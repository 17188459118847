const python_text = `
Python was my first touch for programming during my studies in 2016, 
and it was my go-to language for a long time.
Besides utilizing it in my personal projects, 
I have been able to use it in my work in means of automating tasks 
and as a tool for data science. 
I know my way around popular libraries for back- and frontend 
and I have experience in using Python for different applications across the board. 
These applications include building machine learning models with sk-learn, 
building GUI applications with PyQt (and PySide) and server-side web-applications with Django. 
I am at a comfortable level where I know what to do when jumping into new libraries and projects.
`
export default python_text