import React  from 'react';
import './SkillsItem.css';

import skillsIcons from '../../assets/icons/skillsIcons'

const SkillsItem = (props) => {

    const { name, stars, callBack, currentActive } = props;

    let starsString = "";
    for (let i = 0; i < stars; i++) {
        starsString += "\u2605";
    }
    for (let i = stars; i < 5; i++) {
        starsString += "\u2606";
    }

    let active = "";
    if (currentActive === name) {
        active = "Active";
    }
    else {
        active = "Passive";
    }

    const onClick = (event, arg1) => {
        callBack(name)
    }

    let post = []
    post.push(
        <div 
            key={name}
            className={`SkillsItem ${name} ${active}`} 
            onClick={(event)=>onClick(event,name)}>
            <div className="column">
                    <p className="SkillName">{name}</p>
                <div className="StarsContainer">
                    <p>{starsString} </p>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            {post}
        </div>
    );
}

export default SkillsItem;