import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import './ProjectsContent.css';

import ProjectsItem from '../ProjectsItem/ProjectsItem';


const ProjectsContent = (props) => {

    const [currentProjectId, setCurrentProjectId] = useState(0)
    const { width, breakpoint } = props;

    const onClick = () => {
        window.open("https://github.com/santerilevanto?tab=repositories", '_blank')
    }

    return (
        <div className="Whole-Content">
            <div className="Projects Text-Content">
                <div className={`Projects ${width < breakpoint ? "Column" : "Row"}`}>
                    <div className={`Text-Box ${width < breakpoint ? "Column" : "Row"}`} >
                        <h1>Projects</h1>
                        <p>{`I like to keep my personal projects hidden until there is enough to show for. 
                            These are the projects I have opened to public.`}</p>
                        <div className="Bottom-Section">
                            <div className="Center-Section">
                                <div className="Button" onClick={onClick}>
                                    <h3>My GitHub &#x29c9;</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`Project-Box ${width < breakpoint ? "Column" : "Row"}`}>
                        <ProjectsItem />
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default ProjectsContent;