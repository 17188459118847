import React from 'react';
import image_santeri_small from './Santeri_small.png';


const MainContent = (props) => {

    const { width, breakpoint } = props;

    const onClickMain = () => {
        var element = document.getElementById("Skills")
        element.scrollIntoView()
    }

    return (
        <div className="Whole-Content">
            <div className="Text-Content Main">
                <h1>Santeri Levanto</h1>
                <p>Master of Science, Tech. in Chemical Engineering</p>
                <p>Self-taught Software Engineer</p>
                <div className="Bottom-Section">
                    <div className="Center-Section">
                        <div className="Button" onClick={onClickMain}>
                            <p>More about me &dArr;</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Picture-Content">
                {width > breakpoint ? 
                <img className="Picture" src={image_santeri_small}></img>:
                <img className="PictureSmall" src={image_santeri_small}></img>
                }
            </div>
        </div>
    )
}

export default MainContent;