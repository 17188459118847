const java_text = `
I first encountered Java when I started as a trainee at GloCell in 2017. 
During that time I started to figure it out, 
but it was not until 2020 when I finally was able to participate in coding for production. 
Back then the development was just small tasks here and there with a desktop application
written with Swing. My Java skills took a big leap forward when I joined 3StepIT, 
where the main backend language was Java with SpringBoot as framework. 
During my time at 3StepIT I have evolved into a professional Java developer,
and I still keep on learning more.`

export default java_text