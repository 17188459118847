const git_text = `
Version control is one of the most important concepts of software development, 
but I did not realize it until 2020. I could have been practising it ever since I started coding, 
but I did not. It is better to start late than never though, and that is what I did. 
I also got a good motivation to learn Git at work, 
where I was responsible for changing our version control from CVS to Git. 
This was in late 2020, and I have ever since used version control at work and at home
for even the smallest projects, just to get into the habit of using it. 
I have learned a lot, and I have basic understanding and ability to work with Git and GitHub.

Since I joined 3StepIT I have been improving my git skills tremendously, 
as well as broadning my view from GitHub to Bitbucket.`

export default git_text