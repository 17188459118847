import React, { useState }from 'react';
import './App.css';

import Navbar from './components/Navbar/Navbar';
import FullPage from './components/FullPage/FullPage';

const App = (props) => {

    const [currentCategory, setCategory] = useState("Main");
    const [activeCategories, setActiveCategories] = useState({
        main: true,
        skills: false,
        projects: false,
        other: false
    });

    const categories = [
        "Main",
        "Skills",
        "Projects",
        "Other"
    ]

    const contentCallback = (ref, onScreen) => {
        const name = ref;
        switch (name) {
            case "Main": {
                if (activeCategories.main !== onScreen) {
                    setActiveCategories(prevState => {
                        return {...prevState, main: onScreen }
                    })
                }
                break;
            }
            case "Skills": {
                if (activeCategories.skills !== onScreen) {
                    setActiveCategories(prevState => {
                        return {...prevState, skills: onScreen }
                    })
                }
                break;
            }
            case "Projects": {
                if (activeCategories.projects !== onScreen) {
                    setActiveCategories(prevState => {
                        return {...prevState, projects: onScreen }
                    })
                }
                break;
            }
            case "Other": {
                if (activeCategories.other !== onScreen) {
                    setActiveCategories(prevState => {
                        return {...prevState, other: onScreen }
                    })
                }
                break;
            }
            default: {
                if (activeCategories.other !== onScreen) {
                    setActiveCategories(prevState => {
                        return {...prevState, other: onScreen }
                    })
                }
                break;
            }
        }

        const keys = Object.keys(activeCategories)
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            if (activeCategories[key]) {
                setCategory(categories[i]);
                break;
            }
        }
    }
    
    const navbarCallback = () => {
        console.log("Current:", currentCategory)
        contentCallback(currentCategory, true)
    }

    return (
        <div className="App">
            <Navbar 
            categories={categories}
            currentCategory={currentCategory}
            navbarCallback={() => navbarCallback()}
            />
            <FullPage 
            categories={categories}
            contentCallback={(ref, onScreen)=>contentCallback(ref, onScreen)} 
            />
            <footer>
                <p>Website code and design by Santeri Levanto</p>
            </footer>
        </div>
    );
}

export default App;
