import React, { useState } from 'react';

import './OtherContent.css';


const OtherContent = (props) => {

    const { width, breakpoint } = props;

    const onClick = () => {
        window.open("https://www.linkedin.com/in/santerilevanto/", '_blank')
    }

    return (
        <div className="Whole-Content">
            <div className="Other Text-Content">
                <div className={`Other ${width < breakpoint ? "Column" : "Row"}`}>
                    <div className={`Other Text-Box ${width < breakpoint ? "Column textCenter" : "Row"}`} >
                        <h1 >Work and education</h1>
                        <p >This is a brief CV. Find out more about my education and work history in my LinkedIn</p>
                        <div className="Bottom-Section">
                            <div className="Center-Section">
                                <div className="Button" onClick={onClick}>
                                    <h3>My LinkedIn &#x29c9;</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`Other-Box ${width < breakpoint ? "Column " : "Row"}`}>
                        <p>Started studies at Aalto University in 2012 with Bioproduct Technology as major</p>
                        <p>Worked during summers 2014-2016 in two different papermills, last summer as shift supervisor</p>
                        <p>Started part-time employment at GloCell in 2017 and finished Master's thesis for GloCell in 2019</p>
                        <p>Graduated from Aalto University in 2019 with Chemical Engineering as major and Computer Sciences as minor</p>
                        <p>Started full-time employment at GloCell in 2019</p>
                        <p>Started as cloud consultant at CGI in 2021</p>
                        <p>Started as software developer at 3StepIT in 2023</p>
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default OtherContent;