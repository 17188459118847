import FlatList from 'flatlist-react';

import './FullPage.css';

import ContentPage from '../ContentPage/ContentPage';

const FullPage = (props) => {

    const { categories, contentCallback } = props;


    const renderContent = (category, idx) => {
        return (
            <li key={idx}>
                <ContentPage 
                name={category}
                contentCallback={(ref, onScreen)=>contentCallback(ref, onScreen)}/>
            </li>
        )
    }

    return(
        <div className="MainContainer">
            <ul>
                <FlatList 
                    list={categories}
                    renderItem={renderContent}
                />
            </ul>
        </div>
    )
}

export default FullPage;