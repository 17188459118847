const linux_text = `
I have used Windows as operating system ever since I was kid, 
and I did not have much of an incentive to learn Linux back then. 
When I started to jump deeper into software development, 
I realized how widely Linux is utilized in the server side and became interested 
in basics of Linux environments. At the same time at GloCell I was able to see in action, 
how a Linux server works as part of a software stack. It was a good opportunity to start learning, 
and I quickly got a grasp of basics of Linux system administration as well. 
When I joined CGI, the WSL was introduced me and I got even deeper into Linux and its possibilities.
Nowadays I run Ubuntu with WSL2 and mainly work in that environment.`

export default linux_text