const aws_text = `
Cloud services seem to play a huge part in the future of software development, 
so it was only natural to learn the basics from some cloud provider. 
I chose AWS, but it could have been any of the other providers as well. 
I started to learn out of curiosity, but for now my career has been evolved around AWS services.
I took a big leap forward when I joined CGI as a cloud consultant. 
Back then in the late 2021 I achieved my first certificate - Certified Developer.
I have been able to continue my journey with AWS services at 3StepIT.
`

export default aws_text