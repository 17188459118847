import python_text from './skills_python';
import java_text from './skills_java';
import js_text from './skills_js';
import postgre_text from './skills_postgre';
import git_text from './skills_git';
import linux_text from './skills_linux';
import aws_text from './skills_aws';
import bash_text from './skills_bash';
import docker_text from './skills_docker';

const all_texts = {
    python: {
        name: "Python",
        text: python_text,
    },
    java: {
        name: "Java",
        text: java_text
    },
    js: {
        name: "JavaScript",
        text: js_text
    },
    postgre: {
        name: "PostgreSQL",
        text: postgre_text
    },
    git: {
        name: "Git",
        text: git_text
    },
    linux: {
        name: "Linux",
        text: linux_text
    },
    aws: {
        name: "AWS",
        text: aws_text
    },
    bash: {
        name: "Bash",
        text: bash_text
    },
    docker: {
        name: "Docker", 
        text: docker_text
    }
}

export default all_texts;
