import React, { useState } from 'react';
import Menu from "../Menu/Menu";
import './Navbar.css';

const Navbar = (props) => {

    const { categories, currentCategory, navbarCallback } = props;

    return (
        <div className={"Nav"}
            onClick={navbarCallback}>
            <Menu 
            categories={categories} 
            page={currentCategory} 
            />
        </div>
    );


}

export default Navbar;