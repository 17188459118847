const js_text = `
In 2019 I wanted to broaden my skill-scope from desktop development to web development, 
and naturally that meant learning HTML, CSS and JavaScript. 
At that time I just wanted to learn the basics, so I started to figure stuff out with Django, 
since I wanted to have some comfort from a language I already knew. 
I got the grasp of basics quickly, and at that time I was satisfied with that. 
In 2020 I came back to web development and wanted to continue learning with some 
Javascript framework. 
I ended up with React due to its popularity and large community. 
When I joined 3StepIT in 2023 I took a step forward as JavaScript developer as well.
My job description was mostly backend when I started, but at one point I wanted to 
try my wings with frontend. I was able to test my wings with Vue, and I am on my way 
towards being a fullstack developer.`

export default js_text