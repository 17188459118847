import React, { useEffect, useState }  from 'react';
import './ProjectsItem.css';

import { CSSTransition } from 'react-transition-group';

const ProjectsItem = (props) => {
    const { } = props;

    const [isActive, setActive] = useState(true)
    const [currentProjectId, setCurrentProjectId] = useState(0)
    const [tempId, setTempId] = useState(0)
    const [direction, setDirection] = useState("toRight")
    const [clicked, setClicked] = useState(false);

    const projects = [
        "This website",
        "Project 2",
        "Project 3"
    ]

    const projectsObj = {
        [projects[0]]: `Built to demonstrate my skills with React and 
                        to showcase my tehnology stack and projects`,
        [projects[1]]: "This will be my second project",
        [projects[2]]: "This will be my third project"
    }

    const techObj = {
        [projects[0]]: "Built on React, hosted with AWS",
        [projects[1]]: "<Technologies used>",
        [projects[2]]: "<Technologies used>"
    }

    const timeObj = {
        [projects[0]]: "8/2021",
        [projects[1]]: "TBA",
        [projects[2]]: "TBA"
    }

    const onClick = (event, arg1) => {
        let calcId = (currentProjectId + arg1) % 3;
        if (calcId < 0) {
            calcId = projects.length - 1
        }

        if (arg1 < 0) {
            setDirection("toLeft")
        }
        else {
            setDirection("toRight")
        }
        setActive(false)
        setTempId(calcId)
    }

    const onExited = () => {
        setActive(true)
        setCurrentProjectId(tempId)
    }

    useEffect(() => {
        
    }, [])

    return (
        <div className="ProjectItemBox">
            <button className="ProjectArrow l" onClick={(event)=>onClick(event,-1)}>{"<"}</button>
            <div className="Content">
                <CSSTransition 
                    in={isActive}
                    timeout={300}
                    classNames="my-node"
                    onExited={onExited}>
                    <div className={`Slider ${direction}`}>
                        <h2>{projects[currentProjectId]}</h2>
                        <CSSTransition 
                            in={isActive}
                            timeout={300}
                            classNames="my-node">
                            <div className={`Slider ${direction}`}>
                                <p className ="textCenter">{projectsObj[projects[currentProjectId]]}</p>
                                <p>{techObj[projects[currentProjectId]]}</p>
                                <p>{`Published: ${timeObj[projects[currentProjectId]]}`}</p>
                            </div>
                    </CSSTransition>
                    </div>
                </CSSTransition>
                
            </div>
            <button className="ProjectArrow r" onClick={(event)=>onClick(event,1)}>{">"}</button>
        </div>
    )
}

export default ProjectsItem;