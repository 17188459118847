import React, { useRef } from 'react';
import './MenuItem.css';

const MenuItem = (props) => {

    const { name, active } = props;

    const onClick = (event, arg1) => {
        var element = document.getElementById(name)
        element.scrollIntoView()
    }

        return (
            <div className={`MenuItem ${active ? "Active": "Passive"}`} onClick={(event)=>onClick(event,name)}>
                <p>{name}</p>
            </div>
        );
}

export default MenuItem;