import React, { useRef } from 'react';

import useViewPort from '../../hooks/useViewPort';
import MainContent from '../MainContent/MainContent';
import SkillsContent from '../SkillsContent/SkillsContent';
import ProjectsContent from '../ProjectsContent/ProjectsContent';
import OtherContent from '../OtherContent/OtherContent';

import useOnScreen from '../../hooks/useOnScreen'

import './ContentPage.css';


const ContentPage = (props) => {

    const { name, contentCallback } = props;

    const { width }= useViewPort();
    const breakpoint = 1200;

    const ref = useRef("Main");
    const onScreen = useOnScreen(ref, "-200px");

    const handleNavUpdate = () => {
        contentCallback(name, true)
      };

    let text = []
    if (name === "Main") {
        text.push(
            <MainContent
                key={name} 
                width={width} 
                breakpoint={breakpoint}/>
        )
    }

    else if (name === "Skills") {
        text.push(
            <SkillsContent
                key={name}  
                width={width}
                breakpoint={breakpoint}
            />
        )
    }

    else if (name === "Projects") {
        text.push(
            <ProjectsContent
                key={name} 
                width={width}
                breakpoint={breakpoint}
            />
        )
    }

    else if (name === "Other") {
        text.push(
            <OtherContent
                key={name} 
                width={width}
                breakpoint={breakpoint}
            />
        )
    }
    else {
        text.push(
                <div 
                    key={"blank"}
                    className="Whole-Content">
                    <div className="Text-Content">
                        <h1>{name}</h1>
                        <p>Text-Content</p>
                    </div>
                    <div className="Picture-Content">
                        <p></p>
                    </div>
                </div>
        )
    }
    
    contentCallback(ref.current.id, onScreen)

    return (
        <div ref={ref} id={name}
            onTouchEnd={handleNavUpdate}
            onClick={handleNavUpdate}>
            {text}
        </div>   
    )
}


export default ContentPage;