import React, {useState, useEffect, useRef } from 'react';
import SkillsItem from '../SkillsItem/SkillsItem';

import all_texts from '../../assets/texts/all_texts';

import useOnScreen from '../../hooks/useOnScreen'


const SkillsContent = (props) => {

    const { width, breakpoint } = props;

    const skills_texts = all_texts;

    const [currentName, setName] = useState("Python");
    const [currentText, setText] = useState(all_texts.python.text)
    const [currentActive, setActive] = useState("Python")
    const [clicked, setClicked] = useState(false)

    const ref = useRef();
    const onScreen = useOnScreen(ref, "-500px");

    const hasProjects = {
        ["Python"]: false,
        ["Java"]: false,
        ["JavaScript"]: false,
        ["PostgreSQL"]: false,
        ["Git"]: false,
        ["Linux"]: false,
        ["AWS"]: false,
        ["Bash"]: false,
        ["Docker"]: false,
    }



    

    useEffect(() => {
if (clicked) {
            if (width < breakpoint) {
                var element =  document.getElementById("SkillsText");
                var elementRects= element.getClientRects()
                var offset = 100;
                var scrollPosition = window.scrollY;
                var viewportHeight = window.visualViewport.height;
                var elementPosition = elementRects[0].top 
                var finalPosition = scrollPosition + elementPosition - offset;
                window.scrollTo({
                    top: finalPosition,
                    behavior: "smooth"
               });
            }
        }
        setClicked(false);
    }, [clicked])

    const onClickSkills = () => {
        var element = document.getElementById("Projects")
        element.scrollIntoView()
    }

    const skillsClicked = (name) => {
        if (name == currentName) {
            setClicked(true);
            return;
        }
        setName(name);
        let text = "";
        const skills_keys = Object.keys(skills_texts)
        for (let i = 0; i < skills_keys.length; i++) {
            let key = skills_keys[i]
            let temp_name = skills_texts[key].name
            if (name === temp_name) {
                text = skills_texts[key].text
                break;
            }
        }
        setText(text);
        if (name === currentActive) {
            setActive("")
        }
        else {
            setActive(name)
        }
        setClicked(true);
    }

    return (
        <div className="Whole-Content">   
            <div className={`Text-Content Skills`}>
                <div className={`${width < breakpoint ? "Column" : "Row"}`}>
                    <div>
                        <h3 id="Technologies">Technologies</h3>
                        <div className="Left">
                            
                            <div className="column left">
                                <SkillsItem name={"Python"} 
                                            stars={3} 
                                            callBack={skillsClicked}
                                            currentActive = {currentActive}
                                            />
                                <SkillsItem name={"Java"} 
                                            stars={5} 
                                            callBack={skillsClicked}
                                            currentActive = {currentActive}
                                            />
                                <SkillsItem name={"JavaScript"} 
                                            stars={4} 
                                            callBack={skillsClicked}
                                            currentActive = {currentActive}
                                            />
                                <SkillsItem name={"PostgreSQL"} 
                                            stars={3} 
                                            callBack={skillsClicked}
                                            currentActive = {currentActive}
                                            />
                                <SkillsItem name={"Docker"} 
                                            stars={3} 
                                            callBack={skillsClicked}
                                            currentActive = {currentActive}
                                            />
                            </div>
                            <div className="column right">
                                <SkillsItem name={"Git"} 
                                            stars={4} 
                                            callBack={skillsClicked}
                                            currentActive = {currentActive}
                                            />
                                <SkillsItem name={"Linux"} 
                                            stars={4} 
                                            callBack={skillsClicked}
                                            currentActive = {currentActive}
                                            />
                                <SkillsItem name={"AWS"} 
                                            stars={5} 
                                            callBack={skillsClicked}
                                            currentActive = {currentActive}
                                            />
                                <SkillsItem name={"Bash"} 
                                            stars={3} 
                                            callBack={skillsClicked}
                                            currentActive = {currentActive}
                                            />
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div ref={ref} id="SkillsText" className={`Right SkillsText`}>
                        <div className="SkillsTextContent">
                            <h3 className="SkillsCurrentName">{currentName} </h3>
                            <p className="SkillsCurrentText">{currentText}</p>
                            {hasProjects[currentActive] && 
                            <div className="Dense AlignCenter" >
                                <button className="Button" onClick={onClickSkills}>
                                    <p>
                                        See my projects &dArr;
                                    </p> 
                                </button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="Dense AlignLeft">
                            <p className="Dense">{"\u2605\u2606\u2606\u2606\u2606 = Just the basics"}</p>
                            <p className="Dense">{"\u2605\u2605\u2606\u2606\u2606 = Eager to learn more"}</p>
                            <p className="Dense">{"\u2605\u2605\u2605\u2606\u2606 = I can work with that"}</p>
                            <p className="Dense">{"\u2605\u2605\u2605\u2605\u2606 = Almost professional"}</p>
                            <p className="Dense">{"\u2605\u2605\u2605\u2605\u2605 = I am a professional"}</p>
                </div>
            </div>
        </div>
    )
}

export default SkillsContent;