import React from 'react';
import useViewPort from '../../hooks/useViewPort';

import './Menu.css';
import MenuItem from './../MenuItem/MenuItem'

const Menu = (props) => {

    const { categories, page } = props;

    const { width }= useViewPort();
    const breakpoint = 1000;

    let topics = []
    for (let i = 0; i < categories.length; i++) {
        topics.push(<MenuItem
                    key={i} 
                    name={categories[i]} 
                    active={categories[i]===page ? true : false}
                    />)
    };
    topics.reverse();
    return (
        <div className={`Menu ${width < breakpoint ? "Center" : "Right"}`}>
            {topics}
        </div>
    );


}

export default Menu;